import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import login from "../views/login.vue";
import smsmarketing from "../components/smsmarketing";
import smmSmarketingRecords from "../components/smmSmarketingRecords";
import shortLinks from "../components/shortLinks";
import signatureManagement from "../components/signatureManagement";
import smsStatusInfo from "../components/smsStatusInfo";
import platformBlackNumber from "../components/platformBlackNumber";
import cmppProxy from "../components/cmppProxy";
import platformManagement from "../components/platformManagement";
import groupManagement from "../components/groupManagement";
import urlReportList from "../components/urlReportList";
import passagewayAnalysis from "../components/passagewayAnalysis";
import userAnalysis from "../components/userAnalysis";
import batchNumberReport from "../components/batchNumberReport";
import provinceAnalysis from "../components/provinceAnalysis";
import lastSevenDaysData from "../components/lastSevenDaysData";
import sendMonitoring from "../components/sendMonitoring";
import userNameList from "../components/userNameList";
import signatureBlack from "../components/signatureBlack";
import sendSignCheck from "../components/sendSignCheck";
import userSignatureManagement from "../components/userSignatureManagement";
import signature from "../components/signature";
import platList from "../components/platList";
import sendAnalysis from "../components/sendAnalysis";
import platform from "../components/platform";
import sendAdmin from "../components/sendAdmin";
import simulateData from "../components/simulateData";
import simulateConfig from "../components/simulateConfig";
import simulatedState from "../components/simulatedState";
import sendLimit from "../components/sendLimit";
import messagePush from "../components/messagePush";
import aisleUp from "../components/aisleUp";
import contentStrategy from "../components/contentStrategy";
import sendUser from "../components/sendUser";
import blackList from "../components/blackList";
import blackSms from "../components/blackSms";
import signCorrelationSet from "../components/signCorrelationSet";
import recentSuccessSendInfo from "../components/recentSuccessSendInfo";
import whiteList from "../components/whiteList";
import toAudit from "../components/toAudit";
import preSubmitAudit from "../components/preSubmitAudit";
import failureChannelRetryGroup from "../components/failureChannelRetryGroup";
import channelRetryGroup from "../components/channelRetryGroup";
import channelRetrySendLogPage from "../components/channelRetrySendLogPage";
import shortReplace from "../components/shortReplace";
import statusCode from "../components/statusCode";
import help from "../components/help";
import apiPort from "../components/apiPort";
import system from "../components/system";
import intelligence from "../components/intelligence";
import free from "../components/free";
import clickData from "../components/clickData";
import longUrlDistributeInfo from "../components/longUrlDistributeInfo";
import channelQualityAnalysis from "../components/channelQualityAnalysis";
import randomRouteDataAnalysis from "../components/randomRouteDataAnalysis";
import blankNumberPage from "../components/blankNumberPage";
import userReportList from "../components/userReportList";
import costAnalysis from "../components/costAnalysis";
import batchCostAnalysis from "../components/batchCostAnalysis";
import historyPrice from "../components/historyPrice";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/Home",
    name: "Home",
    redirect: "/smsmarketing",
    components: {
      first: Home,
    },
    children: [
      {
        path: "/smsmarketing",
        name: "smsmarketing",
        components: {
          second: smsmarketing,
        },
      },
      {
        path: "/smmSmarketingRecords",
        name: "smmSmarketingRecords",
        components: {
          second: smmSmarketingRecords,
        },
      },
      {
        path: "/userReportList",
        name: "userReportList",
        components: {
          second: userReportList,
        },
      },
      {
        path: "/shortLinks",
        name: "shortLinks",
        redirect: "/shortLinks/intelligence",
        components: {
          second: shortLinks,
        },
        children: [
          {
            path: "/shortLinks/intelligence",
            name: "intelligence",
            components: {
              three: intelligence,
            },
          },
          {
            path: "/shortLinks/free",
            name: "free",
            components: {
              three: free,
            },
          },
          {
            path: "/shortLinks/clickData",
            name: "clickData",
            components: {
              three: clickData,
            },
          },
          {
            path: "/shortLinks/longUrlDistributeInfo",
            name: "longUrlDistributeInfo",
            components: {
              three: longUrlDistributeInfo,
            },
          },
        ],
      },
      {
        path: "/costAnalysis",
        name: "costAnalysis",
        redirect: "/costAnalysis/batchCostAnalysis",
        components: {
          second: costAnalysis,
        },
        children: [
          {
            path: "/costAnalysis/batchCostAnalysis",
            name: "batchCostAnalysis",
            components: {
              three: batchCostAnalysis,
            },
          },
          {
            path: "/costAnalysis/historyPrice",
            name: "historyPrice",
            components: {
              three: historyPrice,
            },
          },
        ],
      },
      {
        path: "/sendMonitoring",
        name: "sendMonitoring",
        components: {
          second: sendMonitoring,
        },
      },
      {
        path: "/userNameList",
        name: "userNameList",
        components: {
          second: userNameList,
        },
      },
      {
        path: "/help",
        name: "help",
        components: {
          second: help,
        },
        children: [
          {
            path: "/help/statusCode",
            name: "statusCode",
            components: {
              three: statusCode,
            },
          },
          {
            path: "/help/apiPort",
            name: "apiPort",
            components: {
              three: apiPort,
            },
          },
          {
            path: "/help/system",
            name: "system",
            components: {
              three: system,
            },
          },
        ],
      },
      {
        path: "/signature",
        name: "signature",
        components: {
          second: signature,
        },
        children: [
          {
            path: "/signature/signatureManagement",
            name: "signatureManagement",
            components: {
              three: signatureManagement,
            },
          },
          {
            path: "/signature/userSignatureManagement",
            name: "userSignatureManagement",
            components: {
              three: userSignatureManagement,
            },
          },
        ],
      },
      {
        path: "/platform",
        name: "platform",
        components: {
          second: platform,
        },
        children: [
          {
            path: "/platform/platList",
            name: "platList",
            components: {
              three: platList,
            },
          },
          {
            path: "/platform/platformManagement",
            name: "platformManagement",
            components: {
              three: platformManagement,
            },
          },
          {
            path: "/platform/groupManagement",
            name: "groupManagement",
            components: {
              three: groupManagement,
            },
          },
          {
            path: "/platform/smsStatusInfo",
            name: "smsStatusInfo",
            components: {
              three: smsStatusInfo,
            },
          },
          {
            path: "/platform/platformBlackNumber",
            name: "platformBlackNumber",
            components: {
              three: platformBlackNumber,
            },
          },
          {
            path: "/platform/cmppProxy",
            name: "cmppProxy",
            components: {
              three: cmppProxy,
            },
          },
        ],
      },
      {
        path: "/sendAdmin",
        name: "sendAdmin",
        components: {
          second: sendAdmin,
        },
        redirect: "/sendAdmin/sendUser",
        children: [
          {
            path: "/sendAdmin/messagePush",
            name: "messagePush",
            components: {
              three: messagePush,
            },
          },
          {
            path: "/sendAdmin/aisleUp",
            name: "aisleUp",
            components: {
              three: aisleUp,
            },
          },
          {
            path: "/sendAdmin/simulatedState",
            name: "simulatedState",
            components: {
              three: simulatedState,
            },
            children: [
              {
                path: "/sendAdmin/simulatedState/simulateConfig",
                name: "simulateConfig",
                components: {
                  three: simulateConfig,
                },
              },
              {
                path: "/sendAdmin/simulatedState/simulateData",
                name: "simulateData",
                components: {
                  three: simulateData,
                },
              },
            ],
          },
          {
            path: "/sendAdmin/sendUser",
            name: "sendUser",
            components: {
              three: sendUser,
            },
          },
          {
            path: "/sendAdmin/toAudit",
            name: "toAudit",
            components: {
              three: toAudit,
            },
          },
          {
            path: "/sendAdmin/preSubmitAudit",
            name: "preSubmitAudit",
            components: {
              three: preSubmitAudit,
            },
          },
          {
            path: "/sendAdmin/failureChannelRetryGroup",
            name: "failureChannelRetryGroup",
            components: {
              three: failureChannelRetryGroup,
            },
            children: [
              {
                path: "/sendAdmin/failureChannelRetryGroup/channelRetryGroup",
                name: "channelRetryGroup",
                components: {
                  three: channelRetryGroup,
                },
              },
              {
                path: "/sendAdmin/failureChannelRetryGroup/channelRetrySendLogPage",
                name: "channelRetrySendLogPage",
                components: {
                  three: channelRetrySendLogPage,
                },
              },
            ],
          },
          {
            path: "/sendAdmin/shortReplace",
            name: "shortReplace",
            components: {
              three: shortReplace,
            },
          },
          {
            path: "/sendAdmin/contentStrategy",
            name: "contentStrategy",
            components: {
              three: contentStrategy,
            },
          },
        ],
      },
      {
        path: "/sendLimit",
        name: "sendLimit",
        components: {
          second: sendLimit,
        },
        children: [
          {
            path: "/sendLimit/blackList",
            name: "blackList",
            components: {
              three: blackList,
            },
          },
          {
            path: "/sendLimit/recentSuccessSendInfo",
            name: "recentSuccessSendInfo",
            components: {
              three: recentSuccessSendInfo,
            },
          },
          {
            path: "/sendLimit/whiteList",
            name: "whiteList",
            components: {
              three: whiteList,
            },
          },
          {
            path: "/sendLimit/blackSms",
            name: "blackSms",
            components: {
              three: blackSms,
            },
          },
          {
            path: "/sendLimit/signCorrelationSet",
            name: "signCorrelationSet",
            components: {
              three: signCorrelationSet,
            },
          },
          {
            path: "/sendLimit/signatureBlack",
            name: "signatureBlack",
            components: {
              three: signatureBlack,
            },
          },
          {
            path: "/sendLimit/sendSignCheck",
            name: "sendSignCheck",
            components: {
              three: sendSignCheck,
            },
          },
        ],
      },
      {
        path: "/sendAnalysis",
        name: "sendAnalysis",
        components: {
          second: sendAnalysis,
        },
        children: [
          {
            path: "/sendAnalysis/urlReportList",
            name: "urlReportList",
            components: {
              three: urlReportList,
            },
          },
          {
            path: "/sendAnalysis/batchNumberReport",
            name: "batchNumberReport",
            components: {
              three: batchNumberReport,
            },
          },
          {
            path: "/sendAnalysis/provinceAnalysis",
            name: "provinceAnalysis",
            components: {
              three: provinceAnalysis,
            },
          },
          {
            path: "/sendAnalysis/passagewayAnalysis",
            name: "passagewayAnalysis",
            components: {
              three: passagewayAnalysis,
            },
          },
          {
            path: "/sendAnalysis/userAnalysis",
            name: "userAnalysis",
            components: {
              three: userAnalysis,
            },
          },
          {
            path: "/sendAnalysis/lastSevenDaysData",
            name: "lastSevenDaysData",
            components: {
              three: lastSevenDaysData,
            },
          },
        ],
      },
      {
        path: "/channelQualityAnalysis",
        name: "channelQualityAnalysis",
        components: {
          second: channelQualityAnalysis,
        },
        children: [
          {
            path: "/channelQualityAnalysis/randomRouteDataAnalysis",
            name: "randomRouteDataAnalysis",
            components: {
              three: randomRouteDataAnalysis,
            },
          },
          {
            path: "/channelQualityAnalysis/blankNumberPage",
            name: "blankNumberPage",
            components: {
              three: blankNumberPage,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    components: {
      first: login,
    },
  },
  {
    path: "/",
    redirect: "/login",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
