<template>
  <div class="cmppProxyInfo">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>名称：</span>
        <a-input
          placeholder="请输入名称"
          v-model.trim="searchCondition.name"
          @pressEnter="query(1)"
          allowClear
          @change="allowClearChange"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="add" icon="plus">新增</a-button>
      </div>
    </div>
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="tablePagination"
      :loading="loadingTable"
      size="small"
      :rowKey="(record) => record.id"
    >
      <span slot="enabled" slot-scope="text, record">
        <a-switch
          checked-children="已启用"
          un-checked-children="已禁用"
          v-model="text"
          @change="enabledChange(record)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a href="javascript:;" @click="modifyClick(record)">修改</a>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="right"
          okText="确认"
          cancelText="取消"
          @confirm="deleteClick(record)"
        >
          <template slot="title">是否确认删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      :title="title"
      v-model="addKeyVisible"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="名称:">
          <a-input v-model="updateData.name" placeholder="请输入名称" />
        </a-form-item>
        <a-form-item label="代理协议:">
          <a-input v-model="updateData.protocol" placeholder="请输入代理协议" />
        </a-form-item>
        <a-form-item label="内网地址:">
          <a-input
            v-model="updateData.privateHost"
            placeholder="请输入内网地址"
          />
        </a-form-item>
        <a-form-item label="外网地址:">
          <a-input
            v-model="updateData.publicHost"
            placeholder="请输入外网地址"
          />
        </a-form-item>
        <a-form-item label="端口号:">
          <a-input v-model="updateData.port" placeholder="请输入端口号" />
        </a-form-item>
        <a-form-item label="启用状态:">
          <a-switch
            checkedChildren="启用"
            unCheckedChildren="禁用"
            v-model="updateData.enabled"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addKeyVisible = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            :loading="loading"
            @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "cmppProxyInfo",
  data() {
    return {
      title: "",
      searchCondition: {
        name: "",
      },
      groupId: "",
      addKeyVisible: false,
      updateData: {},
      loading: false,
      pageNo: 1,
      tableColumns: [
        {
          title: "名称",
          dataIndex: "name",
          ellipsis: true,
        },
        {
          title: "代理协议",
          dataIndex: "protocol",
          ellipsis: true,
        },
        {
          title: "内网地址",
          dataIndex: "privateHost",
          ellipsis: true,
        },
        {
          title: "外网地址(出口ip)",
          dataIndex: "publicHost",
          ellipsis: true,
        },
        {
          title: "端口号",
          dataIndex: "port",
          ellipsis: true,
        },
        {
          title: "启用状态",
          dataIndex: "enabled",
          ellipsis: true,
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          ellipsis: true,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      tablePagination: {},
      loadingTable: false,
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 点击查询
    query(index) {
      if (index) {
        this.pageNo = index;
      }
      let data = {
        pageNo: this.pageNo,
        pageSize: 10,
        ...this.searchCondition,
      };
      this.loadingTable = true;
      this.$http
        .get("/cmppProxyInfo/cmppProxyInfoPage", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            this.tableDataSource = list;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.changePageItem(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    changePageItem(index) {
      this.query(index);
    },
    // 修改
    modifyClick(val) {
      this.title = "修改";
      this.addKeyVisible = true;
      this.groupId = val.id;
      this.updateData = {
        name: val.name,
        protocol: val.protocol,
        privateHost: val.privateHost,
        publicHost: val.publicHost,
        port: val.port,
        enabled: val.enabled,
      };
    },
    enabledChange(val) {
      this.title = "修改";
      this.groupId = val.id;
      this.updateData = {
        name: val.name,
        protocol: val.protocol,
        privateHost: val.privateHost,
        publicHost: val.publicHost,
        port: val.port,
        enabled: !val.enabled,
      };
      this.add_submit();
    },
    // 新增
    add() {
      this.title = "新增";
      this.addKeyVisible = true;
      this.updateData = {
        name: "",
        protocol: "socks4",
        privateHost: "",
        publicHost: "",
        port: "1080",
        enabled: true,
      };
    },
    // 确定新增
    add_submit() {
      let data = { ...this.updateData };
      if (this.title === "新增") {
        this.$http
          .json_post("/cmppProxyInfo/addCmppProxyInfo", data)
          .then((res) => {
            if (res.result === 200) {
              this.addKeyVisible = false;
              this.$message.success("添加成功");
              this.query();
            }
          });
      } else {
        data.id = this.groupId;
        this.$http
          .json_post("/cmppProxyInfo/editCmppProxyInfo", data)
          .then((res) => {
            if (res.result === 200) {
              this.addKeyVisible = false;
              this.$message.success("修改成功");
              this.query();
            }
          });
      }
    },
    // 点击删除
    deleteClick(val) {
      let data = {
        id: val.id,
      };
      this.$http
        .json_post("/cmppProxyInfo/deleteCmppProxyInfo", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.query();
          }
        });
    },
  },
};
</script>
