<template>
  <div class="lastSevenDaysData">
    <a-row class="pie-card">
      <a-col :span="16">
        <a-button type="primary" @click="periodTotalCountInfo" class="btn-class"
          >刷新</a-button
        >
        <h4>近七日发送分析</h4>
        <div id="sevenContainer" style="height: 400px"></div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "lastSevenDaysData",
  data() {
    return {
      chart: null,
      dv: [],
    };
  },
  mounted() {
    let dateSignList = [];
    let list = [];
    for (let i = 0; i < 7; i++) {
      let date = new Date();
      date.setTime(date.getTime() - 3600 * 1000 * 24 * 1);
      date.setTime(date.getTime() - 3600 * 1000 * 24 * i);
      date = this.$common.transformTime(date).substring(0, 10);
      dateSignList.push(date);
    }
    dateSignList.reverse();
    dateSignList.forEach((item) => {
      let obj = {
        dateSign: item,
        successSendCount: 0,
        failureSendCount: 0,
        waitCallbackCount: 0,
        total: 0,
      };
      list.push(obj);
    });
    this.dv = this.dataTransformation(list);
    this.chartStart();
    this.periodTotalCountInfo();
  },
  methods: {
    // 查询近七日数据
    periodTotalCountInfo() {
      let end = new Date();
      let start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
      end.setTime(end.getTime() - 3600 * 1000 * 24 * 1);
      start = this.$common.transformTime(start).substring(0, 10);
      end = this.$common.transformTime(end).substring(0, 10);
      let data = {
        start: start,
        end: end,
      };
      this.$http.get("/msgSendCount/periodTotalCountInfo", data).then((res) => {
        if (res.result === 200) {
          let chartData = res.data;
          this.$message.success("查询成功");
          if (!chartData || chartData.length < 1) {
            return;
          }
          chartData.forEach((item) => {
            item.total =
              item.successSendCount +
              item.failureSendCount +
              item.waitCallbackCount;
          });
          let dv = this.dataTransformation(chartData);
          this.chart.setOption({
            xAxis: {
              data: dv.map((item) => item.dateSign),
            },
            series: [
              {
                name: "发送成功",
                type: "bar",
                stack: "total",
                data: dv.map((item) => item["发送成功"]),
                itemStyle: {
                  color: "#67C23A",
                },
              },
              {
                name: "等待回执",
                type: "bar",
                stack: "total",
                data: dv.map((item) => item["等待回执"]),
                itemStyle: {
                  color: "#E6A23C",
                },
              },
              {
                name: "发送失败",
                type: "bar",
                stack: "total",
                data: dv.map((item) => item["发送失败"]),
                itemStyle: {
                  color: "#F56C6C",
                },
              },
            ],
          });
        }
      });
    },
    // 绘制近七日图
    chartStart() {
      this.chart = echarts.init(document.getElementById("sevenContainer"));
      this.chart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow", // 可以设置为'line'或'shadow'来显示不同类型的指示器
          },
          formatter: (params) => {
            let totalValue = 0;
            let result = "";

            // 计算 totalValue
            params.forEach((param) => {
              if (param.value) {
                totalValue += param.value;
              }
            });

            // 显示x轴的刻度
            result += `${params[0].axisValueLabel}<br/>`;

            // 遍历每个legend，显示百分比和legend的颜色
            params.forEach((param) => {
              const value = param.value || 0;
              const percentage =
                totalValue > 0
                  ? ((value / totalValue) * 100).toFixed(2) + "%"
                  : "0%";

              // 使用legend圆点颜色
              const color = param.color || "#000"; // 如果ECharts无法获取颜色，默认用黑色
              result += `<span style="display:inline-block;width:10px;height:10px;border-radius:50%;background-color:${color};margin-right:10px;"></span>`;
              result += `${param.seriesName}: ${value} | ${percentage}<br/>`;
            });

            return result;
          },
        },
        legend: {
          data: ["发送成功", "等待回执", "发送失败"],
          bottom: 0,
        },
        xAxis: {
          type: "category",
          data: this.dv.map((item) => item.dateSign),
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "发送成功",
            type: "bar",
            stack: "total",
            data: this.dv.map((item) => item["发送成功"]),
            itemStyle: {
              color: "#67C23A",
            },
          },
          {
            name: "等待回执",
            type: "bar",
            stack: "total",
            data: this.dv.map((item) => item["等待回执"]),
            itemStyle: {
              color: "#E6A23C",
            },
          },
          {
            name: "发送失败",
            type: "bar",
            stack: "total",
            data: this.dv.map((item) => item["发送失败"]),
            itemStyle: {
              color: "#F56C6C",
            },
          },
        ],
      });
    },
    // 柱状图数据转换
    dataTransformation(chartData) {
      let list = [];
      chartData.forEach((item) => {
        let obj = {
          dateSign: item.dateSign,
          发送成功: item.successSendCount,
          发送失败: item.failureSendCount,
          等待回执: item.waitCallbackCount,
          total: item.total,
        };
        list.push(obj);
      });
      return list;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-class {
  margin-bottom: 20px;
}
</style>
